import { RequestHelper } from "../Helpers/RequestHelper.js";

export class PmApiDtm {
    constructor(pmchat) {        
        this.pmchat = pmchat;
    }

    getRootURL() {
        if (this.pmchat.isProduction()) {
            return "https://api.dtm.pixelmotiondemo.com/";
        }
        return "http://pmm-api.loc/";
        // return "https://api.dtm.pixelmotiondemo.com/";
    }

    getURL() {
        return this.getRootURL() + "api/v1/";
    }

    postLead(data, successCallback, errorCallback) {
        let url = this.getURL() + "lead/save/drs";
        this.getIPAddress(
            (response) => {
                let ip = response
                    .split("\n")
                    .filter((el) => el.startsWith("ip"))
                    .join("\n");
                data.set("ip", ip.substring(3));
                RequestHelper.ajax(
                    "POST",
                    url,
                    data,
                    successCallback,
                    errorCallback
                );
            },
            (error) => {
                data.set("ip", "");
            }
        );
    }

    // Method for getting the client ip
    getIPAddress(successCallback, errorCallback) {
        const CLOUDFLARE = "https://www.cloudflare.com/cdn-cgi/trace";
        RequestHelper.ajax(
            "GET",
            CLOUDFLARE,
            {},
            successCallback,
            errorCallback
        );
    }

    getVinDecode(data, successCallback, errorCallback) {
        RequestHelper.ajax(
            "GET",
            this.getURL() + "crs/" + data.vin,
            {},
            successCallback,
            errorCallback
        );
    }

    getVehicle(vin, successCallback, errorCallback){
        var siteToken = this.pmchat.config.site_token;
        RequestHelper.ajax(
            "GET",
            this.getURL() + "vehicle/" + siteToken + "/" + vin,
            {},
            successCallback,
            errorCallback
        );
    }
}
