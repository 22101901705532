import { FormatHelper } from "../Helpers/FormatHelper.js";

export class RequestHelper {
    /**
     * [ajax description]
     * @param  {[String]} method [description]
     * @param  {[String]} url    [description]
     * @param  {[String]} data   [JSON]
     * @return {[type]}        [description]
     */
    static ajax(method, url, data, successCallback, errorCallback) {
        let pmApiUrl = url.includes("api.dtm") || url.includes("pmm-api") ? true : false;
        var xhttp = new XMLHttpRequest();
        url = method.toLowerCase() == "get" ? RequestHelper.getUrl(url, data) : url;
        xhttp.open(method, url, true);
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                // Response
                var response = this.responseText;
                if (successCallback) {
                    successCallback(response);
                }
            }
        };

        xhttp.onerror = () => {
            console.log("Error on ajax request");
            if (errorCallback) {
                errorCallback();
            }
        };

        xhttp.onloadend = function () {
            let response = this.responseText;
            if (xhttp.status == 404 || xhttp.status == 400) {
                errorCallback(response);
            }
        };

        if (method.toLowerCase() == "get") {
            return xhttp.send(null);
        } else {
            // xhttp.setRequestHeader("Content-Type", "text/plain");

            // add headers and stringify the data only if the request is not to save the lead            
            if (!(data instanceof FormData)) {
                xhttp.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
                xhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                return xhttp.send(JSON.stringify(data));
            }

            xhttp.send(data);
        }
    }

    static newXhttp(successCallback, errorCallback){        
        var xhttp = new XMLHttpRequest();        
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                // Response
                var response = this.responseText;
                if (successCallback) {
                    successCallback(response);
                }
            }
        };

        xhttp.onerror = () => {
            console.log("Error on ajax request");
            if (errorCallback) {
                errorCallback();
            }
        };

        xhttp.onloadend = function () {
            let response = this.responseText;
            if (xhttp.status == 404 || xhttp.status == 400) {
                errorCallback(response);
            }
        };
     
        return xhttp;   
    }

    static postMultipart(url, data, successCallback, errorCallback) {        
        var xhttp = new RequestHelper.newXhttp(successCallback, errorCallback);        

        xhttp.open('POST', url, true);
        // xhttp.setRequestHeader("Content-Type", "multipart/form-data; charset=UTF-8");
        // xhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhttp.send(data);            
        
    }

    

    static getUrl(url, data) {
        return url + "?" + FormatHelper.serialize(data);
    }
}
