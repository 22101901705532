import { FormatHelper } from "../Helpers/FormatHelper.js";
import { DOMHelper } from "../Helpers/DOMHelper.js";
import { RequestHelper } from "../Helpers/RequestHelper.js";
import { Modal } from "../component/Modal.js";
import { VoiceCall } from "../admin/VoiceCall.js";
import moment from 'moment';

export class Chat {
    /**
     * @param  {string} parentId dom element id
     * @return {void}
     */
    constructor(app) {
        this.app = app;
        this.parentId = app.config.elementId;
        this.prefixSelector = "#" + this.parentId;
        this.classAtt = "pmc-widget";
        this.site_token = this.app.pmchat.config.site_token;
        this.selectedConversation = {};
        this.conversations = [];
        this.videoChatVersion = 0;//0 twilio, 1 jitsi
        this.currentWindow = 'home';//home, chat, customer
        this.isEditMode = false;//IF CURRENT WINDOW IS ON EDIT MODE
        this.selectedConvoList = [];
        this.currentVoiceCall = null;
        this.defaultConversationOpened = false; 
    }

    init() {
        let $this = this;
        this.addHtml();
        this.initModals();        
        this.addHandlers();
        this.addIframeMessagesHandlers();
        this.loadConversations();

    }      

    addHtml() {
        let $this = this;

        document
            .querySelector($this.app.getSelector(""))
            .insertAdjacentHTML("beforeend", `<div class="pmc-widget">
	
	<!-- HEADER MOVED TO EACH BODY PIECE -->

	<div class="pmc-window-container">
		
		


<div class="pmc-main-chat pmc-window">
	<!-- start list header -->
<div class="pmc-header">
    <div class="pmc-headline">
        <div class="pmc-title-wrapper">
            <div class="pmc-title">Messages</div>            
        </div>
        <div class="pmc-menu-wrapper">
            <div class="pmc-menu-btn"><span></span><span></span><span></span></div>
            <!-- <ul class="pmc-menu-list">
                <li></li>
            </ul> -->
        </div>
    </div>

    <div class="loading-wrapper conversation-list-loading">
        <div class="lds-dual-ring"></div>
      </div>    
</div>

<!-- end list header -->

	<div class="pmc-body">
		<!-- Start: App Main Chat Edit -->
      <div class="pmc-edit-bar">
        <div class="pmc-edit-bar-wrapper">
          <div class="pmc-edit-bar-left">
            <div class="pmc-edit-bar-icons">
              <!-- <span><i class="fa-solid fa-inbox"></i> Archive</span> -->
              <span class="pmc-delete-btn"><i class="pmc-fa pmc-fa-trash-can"></i> Trash</span>
            </div>
          </div>
          <div class="pmc-edit-bar-right">
            <span class="pmc-cancel-btn">Cancel</span>
          </div>
        </div>
      </div>
      <!-- End: App Main Chat Edit -->

		<div class="conversations-list"></div>	
	</div>
</div>

		

<div class="conversation pmc-chat-window pmc-window">	
	<!-- START: conversation header -->
<div class="pmc-header">
    <div class="pmc-headline">
        <div class="pmc-title-wrapper">
            <div class="pmc-back-btn">
                <i class="pmc-fa pmc-fa-arrow-left"></i>
            </div>
        </div>
        <div class="pmc-menu-wrapper">
            <div class="pmc-menu-btn"><span></span><span></span><span></span></div>
            <ul class="pmc-menu-list">
                <li class="pmc-delete-btn"><i class="pmc-fa pmc-fa-trash-can"></i>  Trash Conversation</li>
                <li class="pmc-details-btn"><i class="pmc-fa pmc-fa-user"></i>  View User Details</li>
            </ul>
        </div>
    </div>
    <!-- Start: App Customer Header -->
    <div class="pmc-cust-header">
        <div class="pmc-cust-header-wrapper">
            <div class="pmc-user-icon">
                <i class="pmc-fa pmc-fa-user"></i>
            </div>
            <div class="pmc-cust-header-name customer-name-value">
                User
            </div>
        </div>
    </div>
    <!-- End: App Customer Header -->
    <div class="loading-wrapper conversation-loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>
<!-- END: conversation header -->

	<div class="pmc-floating-menu-curtain"></div>

	<div class="pmc-body">

		<!-- Start: App Main Chat Edit -->
      <!-- <div class="pmc-edit-bar">
        <div class="pmc-edit-bar-wrapper">
          <div class="pmc-edit-bar-left">
            <div class="pmc-edit-bar-icons">              
              <span class="pmc-delete-btn"><i class="pmc-fa pmc-fa-trash-can"></i> Trash conversation</span>
            </div>
          </div>
          <div class="pmc-edit-bar-right">
            <span class="pmc-cancel-btn">Cancel</span>
          </div>
        </div>
      </div> -->
      <!-- End: App Main Chat Edit -->

		<div class="conversation-messages pmc-chat-window-wrapper">
		</div>

		<div class="pmc-chat-send-wrapper">
	        <div class="pmc-cta-btn-wrapper">
	          <div class="pmc-cta-btn pmc-cta-call start-call-btn">
	            <i class="pmc-fa pmc-fa-phone"></i>
	          </div>
	          <div class="pmc-cta-btn pmc-cta-image upload-img-btn" data-target='#sms_image_input'>
	            <i class="pmc-fa pmc-fa-image" style="pointer-events: none"></i>
	          </div>
	          <!-- <div class="pmc-cta-btn pmc-cta-emoji">
	            <i class="pmc-fa pmc-fa-smile"></i>
	          </div> -->
	          <div class="pmc-cta-btn pmc-cta-video open-video-chat-btn">
	            <i class="pmc-fa pmc-fa-video"></i>
	          </div>
	          <!-- <div class="pmc-cta-btn pmc-cta-link start-video-chat-btn">
	            <i class="pmc-fa pmc-fa-link"></i>
	          </div> -->
	        </div>
	        <div class="pmc-send-field">
	          <form class="pmc-send-field-form" id="send-message-form">
				<input type="hidden" class="conversation-id-value" name="conversation_id" />
	            <input name='body' type="text" placeholder="Type your message here" class="pm-chat-message-input" />
	            <button class="pmc-chat-submit" type="submit">
	            	<i class="pmc-fa pmc-fa-arrow-up"></i>
	            </button>
	          </form>
	        </div>
			<span class="body-counter">160</span>
			<div class="loading-wrapper send-message-loading">
	        	<div class="lds-dual-ring"></div>
	        </div>	
	      </div>
	      	
	</div>

</div>

		<!-- Start: App Customer Details -->
<div class="pmc-window pmc-customer-box">
    <!-- START: conversation header -->
<div class="pmc-header">
    <div class="pmc-headline">
        <div class="pmc-title-wrapper">
            <div class="pmc-back-btn">
                <i class="pmc-fa pmc-fa-arrow-left"></i>
            </div>
        </div>
        <div class="pmc-menu-wrapper">
            <div class="pmc-menu-btn"><span></span><span></span><span></span></div>
            <ul class="pmc-menu-list">
                <li class="pmc-delete-btn"><i class="pmc-fa pmc-fa-trash-can"></i>  Trash Conversation</li>
                <li class="pmc-details-btn"><i class="pmc-fa pmc-fa-user"></i>  View User Details</li>
            </ul>
        </div>
    </div>
    <!-- Start: App Customer Header -->
    <div class="pmc-cust-header">
        <div class="pmc-cust-header-wrapper">
            <div class="pmc-user-icon">
                <i class="pmc-fa pmc-fa-user"></i>
            </div>
            <div class="pmc-cust-header-name customer-name-value">
                User
            </div>
        </div>
    </div>
    <!-- End: App Customer Header -->
    <div class="loading-wrapper conversation-loading">
        <div class="lds-dual-ring"></div>
    </div>
</div>
<!-- END: conversation header -->

    <div class="pmc-body">
	    <div class="pmc-cust-box-wrapper">
	        <div class="pmc-cust-profile">
	            <div class="pmc-info-section-title">Customer Information</div>
	            <ul class="pmc-info-list">
	                <li>Name: <span class="customer-name-value"></span></li>
	                <li><span class="pmc-info-left">Phone: <span class="customer-number-value"></span></span><span class="pmc-info-right start-call-btn"><a href="#"><i class="pmc-fa pmc-fa-phone"></i></a></span></li>
	                <li><span class="pmc-info-left">Email: <span class="customer-email-value"></span></span><span class="pmc-info-right"><a href="#"><i class="pmc-fa pmc-fa-envelope"></i></a></span></li>
	            </ul>
	            <div class="pmc-info-section-title">Vehicle of Interest</div>
	            <ul class="pmc-info-list pmc-voi-info">
	                <!-- <li><span class="pmc-voi-left">2023 BMW F 750<br /><span class="pmc-voi-vin">1C4SJVFJ6NS106842</span></span><span class="pmc-voi-right"><a href="#"><i class="fa-solid fa-magnifying-glass"></i></a></span></li>
	                <li><span class="pmc-voi-left">2023 BMW K 1600<br /><span class="pmc-voi-vin">1C4SJVFJ6NS106845</span></span><span class="pmc-voi-right"><a href="#"><i class="fa-solid fa-magnifying-glass"></i></a></span></li>
	                <li><span class="pmc-voi-left">2023 BMW G 310<br /><span class="pmc-voi-vin">1C4SJVFJ6NS106843</span></span><span class="pmc-voi-right"><a href="#"><i class="fa-solid fa-magnifying-glass"></i></a></span></li> -->
	            </ul>
	        </div>
	    </div>
    </div>    
</div>
<!-- End: App Customer Details -->

		<div class="pmc-window pm-chat-video-call">
	<iframe src="" frameborder=0 allow="camera *;microphone *"></iframe>
</div>
	</div>
	
	<div class="pmc-footer">Powered by <a href="https://www.pixelmotion.com" target="_blank">Pixel Motion</a></div>

</div>`);        
    }

    initModals() {
        var chat = this;
        var html = `<form enctype="multipart/form-data" class="upload-image-form">
  <input type="hidden" name="conversation_id" class="conversation-id-value" />
  <input accept="image/*" type='file' id="sms_image_input" class="pmc-image-input" />
  <img id="sms_image_input-preview" src="#" alt="Image" class="img-preview" />
  <div class="text-center">
    <button class="pmc-button" type="submit">Send</button>
  </div>
</form>`;
        // see details form        
        this.uploadImageModal = new Modal(
            this.app,
            "upload-image-modal",
            html,
            null,
            null
        );
        this.uploadImageModal.init();   

        //VOICE CALL MODAL
        html = `<div class="pmc-voice-call">
	<button class="mute-voice-call-btn">Mute</button>
	<button class="mute-voice-call-btn" style="display: none">Unmute</button>
	<button class="end-voice-call-btn">Hang up</button>
</div>`;
        // see details form        
        this.voiceCallModal = new Modal(
            this.app,
            "voice-call-modal",
            html,
            null,
            null
        );
        this.voiceCallModal.init();        
        this.voiceCallModal.setHideCallback(() => {
            chat.currentVoiceCall.end();
        });        
    }

    handleIncomingMsg(messageObject){
        let selector = null;

        if(this.selectedConversation.tw_conversation_sid == messageObject.conversationSid){
            // IS ACTIVE CONVERSATION
            this.addMessageHistory(messageObject,true);
        }else{
            // IS NOT ACTIVE
            selector = this.app.getSelector('[data-conversation_sid=' +messageObject.conversationSid +']');
            let conversationItem = document.querySelector(selector);  


            // let pc = conversationItem.querySelector('.pending-count')
            // pc.innerHTML = (parseInt(pc.innerHTML) || 0) + 1;
        }

    }

    showHome(){
        let selector = this.app.getSelector(".pmc-widget");        
        let widget = document.querySelector(selector);  
        DOMHelper.removeClass(widget, 'pmc-chat');
        DOMHelper.removeClass(widget, 'pmc-customer')
        this.currentWindow = 'home';
        this.loadConversations();
    }

    showChat(){
        let selector = this.app.getSelector(".pmc-widget");        
        let widget = document.querySelector(selector);  
        DOMHelper.addClass(widget, 'pmc-chat');
        DOMHelper.removeClass(widget, 'pmc-customer')
        this.currentWindow = 'chat';
    }

    showCustomerInfo(){
        let selector = this.app.getSelector(".pmc-widget");        
        let widget = document.querySelector(selector);  
        DOMHelper.addClass(widget, 'pmc-customer')
        // DOMHelper.removeClass(widget, 'pmc-chat');
        this.currentWindow = 'customer';
    }

    editListMode(activate){
        let selector = this.app.getSelector(".pmc-main-chat");        
        let mainChatWindow = document.querySelector(selector);  
        if(activate){            
            DOMHelper.addClass(mainChatWindow, 'edit');
        }else{
            DOMHelper.removeClass(mainChatWindow, 'edit');
            this.selectedConvoList = [];
            selector = this.app.getSelector('.pmc-window .radio-btn');
            DOMHelper.removeClass(selector, 'active');//RESET RADIO BTNS
        }        
        this.isEditMode = activate;
    }

    editChatMode(activate){
        let selector = this.app.getSelector(".conversation");        
        let conversationWindow = document.querySelector(selector);  
        selector = this.app.getSelector(".pmc-floating-menu-curtain");                
        let curtain = document.querySelector(selector);  

        if(activate){            
            DOMHelper.addClass(conversationWindow, 'edit');
            DOMHelper.addClass(curtain, 'active');
        }else{
            DOMHelper.removeClass(conversationWindow, 'edit');            
            DOMHelper.removeClass(curtain, 'active');
        }        
        this.isEditMode = activate;
    }


    addMessageHistory(messageObject, append){
        let msgContent = `<div class="pmc-msg-wrapper msg_class">
    <div class="pmc-msg-author">msg_author</div>
    <div class="pmc-msg-body">msg_body</div>
  </div>`;
        let selector = this.app.getSelector('.conversation-messages');
        let messagesList = document.querySelector(selector);    
        let msgNode = document.createElement('div');        
        let isDealerMsg = messageObject.author == this.site_token;
        let msgClass = isDealerMsg
        ? "pmc-dlr-msg-line"
        : "pmc-cust-msg-line";

        let username = isDealerMsg
            ? "Me"
            : this.selectedConversation.customer_first;

        let messageDate = messageObject.dateCreated
            ? moment(messageObject.dateCreated.date).utc(true).local()
            : moment();                

        let time = messageDate.format('LT');        
        let humanDate = messageDate.format('L');

        let body =  messageObject.body;
        //RENDER LINK
        if(body.includes('http') && false){//SKIP FOR NOW
            body = "<a target=_blank href="+body+">" 
            + body 
            + "</a>"
        }

        //MEDIA SPOTS
        let media = messageObject.media || [];        
        let hasMedia = media.length > 0;
        if(hasMedia){
            media.forEach(m => {
                body = '<img class="msg-media-img" src="" data-mediasid="'+ m.sid+ '"></img>' + body;
            })
        }

        msgClass += hasMedia ? ' has-media' : '';
        
        msgContent = msgContent
            .replace(/msg_class/g, msgClass)
            .replace(/msg_author/g, username +` ${time} ${humanDate}`)
            .replace(/msg_body/g, body)
    
        let div = document.createElement('div');
        div.innerHTML = msgContent
        //ONLY APPEND WORKING BTM
        if(append){
            messagesList.append(div.firstChild);
        }else{
            messagesList.prepend(div.firstChild);
        }

        messagesList.scrollTo(0, messagesList.scrollHeight);
    }    

    appendConversation(conversationObject, reverse){
        reverse = reverse || false;
        console.log('conversationObject');
        console.log(conversationObject);
        let selector = this.app.getSelector('.conversations-list');
        let list = document.querySelector(selector);    
        let conversationNode = document.createElement('div');
        let conversationContent = `<!-- <div>
	<p>customer_phone</p>
	<p>customer_name</p>	
	<p>customer_message</p>	
	<span class="delete-conversation-btn" data-cid="conversation_id">x</span>
</div> -->
<div class="pmc-chat-item">
    <div class="pmc-chat-item-edit">
        <span class="radio-btn">
            
        </span>
    </div>
    <div class="pmc-user-icon">
        <i class="pmc-fa pmc-fa-user"></i>
    </div>
<!--     <div class="pmc-chat-item-arrow">
        <i class="fa-solid fa-chevron-right"></i>
    </div> -->
    <div class="pmc-chat-item-wrapper">
        <div class="pmc-customer-name"><span class="pmc-new-msg"></span>customer_name</div>
        <div class="pmc-chat-time-date">
            <span class="pmc-chat-time">last_msg_time</span><span class="pmc-chat-date">last_msg_date</span>
        </div>
        <div class="pmc-delete-single-btn"><i class="pmc-fa pmc-fa-trash-can"></i></div>

        <!-- <span class="pending-count"></span>     -->
        <div class="pmc-chat-snippet">customer_message</div>
    </div>
</div>`;
        let lastMsg = conversationObject.last_message 
            ? conversationObject.last_message.Body 
            : conversationObject.customer_message;


        let lastMsgDate = conversationObject.last_message 
            ? moment(conversationObject.last_message.DateCreated) //it already has timezone
            : moment();

        console.log('lastMsgDate');
        console.log(lastMsgDate);

        conversationContent = conversationContent                   
            .replace(/customer_phone/g, conversationObject.customer_phone)
            .replace(/customer_name/g, conversationObject.customer_first + " " + conversationObject.customer_last)
            .replace(/customer_message/g, lastMsg)            
            .replace(/last_msg_date/g, lastMsgDate.format('L'))           
            .replace(/last_msg_time/g, lastMsgDate.format('LT'))

        conversationNode.id = "convo_" + conversationObject._id;
        conversationNode.dataset.conversation_sid = conversationObject.tw_conversation_sid;        
        conversationNode.innerHTML = conversationContent        
        DOMHelper.addClass(conversationNode, 'conversation-item');

        this.registerConversationHandler(conversationNode, conversationObject);

        if(reverse){
            list.prepend(conversationNode);
        }else{
            list.append(conversationNode);
        }
    }    

    registerConversationHandler(conversationNode, conversationObject){
        var chat = this;
        DOMHelper.addHandler(conversationNode, 'click', () => { 
            if(chat.isEditMode){
                DOMHelper.triggerEvent(conversationNode.querySelector('.radio-btn'), 'click');
            }else{
                chat.selectConversation(conversationObject);
            }
            // conversationNode.querySelector(".pending-count").innerHTML = "";
        })     

        DOMHelper.addHandler(conversationNode.querySelector('.radio-btn'), 'click', (e) => {            
            var radioBtn = e.target;
            var isActive = DOMHelper.hasClass(radioBtn, 'active');
            if(isActive){
                DOMHelper.removeClass(radioBtn, 'active');
                chat.selectedConvoList = chat.selectedConvoList.filter(convoObject=>{
                    return convoObject._id != conversationObject._id;
                });
            }else{
                DOMHelper.addClass(radioBtn, 'active');
                chat.selectedConvoList.push(conversationObject);
            }            
            console.log('CURRENT IN LIST');
            console.log(chat.selectedConvoList);
            e.stopPropagation();
        });

        //DELETE SINGLE CONVO
        DOMHelper.addHandler(conversationNode.querySelector(".pmc-delete-single-btn"), 
            "click", (e) => {            
            e.stopPropagation();            
            if(window.confirm("Are you sure?")){                
                chat.deleteConversation(conversationObject);
            }
        });

        // DOMHelper.addHandler(
        //     conversationNode.querySelector('.delete-conversation-btn'), 
        //     'click', 
        //     (e) => {     
        //         e.stopPropagation();                       
        //         if(window.confirm("Are you sure?")){
        //             chat.deleteConversation(conversationObject, conversationNode);
        //         }
        // });
    }

    deleteConversation(conversationObject, successCallback){
        var selector = this.app.getSelector("#convo_" +  conversationObject._id)
        var conversationNode = document.querySelector(selector);

        var chat = this;
        var data = {
            conversation_id: conversationObject._id
        };
        return this.app.pmchat.pmSmsChat.deleteConversation(
            data,
            (response) => {
                conversationNode.remove();
                if(successCallback){
                    successCallback();
                }
            },
            () => {
                console.error('Error deleting conversation')                
            },
        );
    }

    clearCurrentConversation() {
        let selector = this.app.getSelector('.conversation-messages');
        document.querySelector(selector).innerHTML = "";
    }

    selectConversation(conversationObject){
        let selector = this.app.getSelector('.conversation');
        let conversation = document.querySelector(selector);    
        // DOMHelper.addClass(conversation, 'active');
        this.showChat();

        this.clearCurrentConversation();

        this.loadConversationMessages(conversationObject._id);
        //SET HIDDEN FIELD
        selector = this.app.getSelector('.conversation-id-value');        
        DOMHelper.setValue(selector, conversationObject._id);

        selector = this.app.getSelector('.customer-number-value');
        DOMHelper.setInnerHTML(selector, conversationObject.customer_phone);
        selector = this.app.getSelector('.customer-name-value');
        DOMHelper.setInnerHTML(selector, conversationObject.customer_first + " " + conversationObject.customer_last);
        selector = this.app.getSelector('.customer-email-value');
        DOMHelper.setInnerHTML(selector, "");

        this.selectedConversation = conversationObject;

        //API DTM INFO 
        this.loadVehicleInfo(conversationObject.vin);

    }

    startVoiceCall(conversationObject){            
        var chat = this;
        chat.voiceCallModal.show();
        var voiceCall = new VoiceCall(chat.app, conversationObject);
        voiceCall.start();
        chat.currentVoiceCall = voiceCall;
    }    

    getBodyInputElement(){
        let selector = this.app.getSelector(".pm-chat-message-input")
        return document.querySelector(selector);
    }

    sendMessage(values){     
        if(!values.conversation_id){
            return;
        }

        var chat = this;
        chat.showLoading('send-message');
        //DISABLE BTN
        var submitBtn = document.querySelector(chat.app.getSelector('.pmc-chat-submit'));
        submitBtn.disabled = true;
        
        console.log(values);            
        return chat.app.pmchat.pmSmsChat.postDealerMessage(                
            values,
            (response) => {                
                response = JSON.parse(response);
                // chat.addMessageHistory({
                //     author: chat.site_token,
                //     body: values.body                    
                // }, true);
                // console.log(response);
                chat.hideLoading('send-message');
                submitBtn.disabled = false;
                let bodyInputElement = chat.getBodyInputElement();
                bodyInputElement.value = "";
                DOMHelper.triggerEvent(bodyInputElement, 'change');                    
            },
            () => {
                console.error('Error sending dealer message')
            }
        );
    }

    addHandlers() {
        let chat = this;
        //SUBMIT
        let sendMessageSubmit = function (event) {
            event.preventDefault();

            //FORM DATA AS JSON
            const data = new FormData(event.target);
            const values = Object.fromEntries(data.entries());

            chat.sendMessage(values);
            return false;
        };

        DOMHelper.addHandler(chat.app.getSelector("#send-message-form"), "submit", sendMessageSubmit);


        let bodyChangeHandler = function (event) {
            let  bodyCount = event.target.value.length;
            let counterElement = document.querySelector(chat.app.getSelector(".body-counter"));
            counterElement.innerHTML = 160 - bodyCount;
        }
        let bodyInputSelector = chat.getBodyInputElement();
        DOMHelper.addHandler(bodyInputSelector, "keyup", bodyChangeHandler);
        chat.getBodyInputElement().onchange = bodyChangeHandler;
        chat.getBodyInputElement().onpaste = bodyChangeHandler;


        let sendVideoChatBtnClick = function(callback){
            //FORM DATA AS JSON
            const data = new FormData(document.querySelector(chat.app.getSelector('#send-message-form')));
            var values = Object.fromEntries(data.entries());
            var conversationId = values.conversation_id;
            if(conversationId){       
                var convo = chat.selectedConversation;         
                var videoCallUrl = chat.app.pmchat.pmSmsChat.getConvoVideoLink(conversationId, convo.customer_phone);
                // var videoCallUrlDealer = chat.app.pmchat.pmSmsChat.getConvoVideoLink(conversationId, convo.site_token);

                // if(chat.videoChatVersion == 1){
                //     videoCallUrl = chat.app.pmchat.pmSmsChat.getConvoVideoLinkV1(conversationId);
                //     videoCallUrlDealer = videoCallUrl;
                // }
                
                chat.app.pmchat.pmShortUrl.getShortUrl(videoCallUrl,
                    (response) => {
                        response = JSON.parse(response);
                        values.body = "Click the link to join the video conference call: " + response.default_short_url;
                        // values.body = "Click to join the video call: " + videoCallUrl;

                        //SMS VIDEO CALL URL
                        if(chat.app.pmchat.isProduction()){
                            chat.sendMessage(values);
                        }else{
                            alert('sending ' + values.body);
                        }
                        if(callback){
                            callback();
                        }
                        
                    },
                    () => {
                        console.error('Error getting tiny url');
                    })
            }
        }

        let startVideoChatBtnClick = function () {
            sendVideoChatBtnClick(function(){    
                var convo = chat.selectedConversation;         
                var videoCallUrlDealer = chat.app.pmchat.pmSmsChat.getConvoVideoLink(convo._id, convo.site_token);                    
                //OPEN VIDEO CALL 
                chat.startVideoCall(videoCallUrlDealer);    
            });
        }

        DOMHelper.addHandler(chat.app.getSelector(".open-video-chat-btn"), "click", startVideoChatBtnClick);        
        DOMHelper.addHandler(chat.app.getSelector(".start-video-chat-btn"), "click", sendVideoChatBtnClick);        

        
        // OPEN VIDEO CHAT
        // DOMHelper.addHandler(chat.app.getSelector(".open-video-chat-btn"), 
        //     "click", () => {            
        //          var convo = chat.selectedConversation;         
        //         let conversationId = convo._id;
        //         if(conversationId){
        //             var videoCallUrlDealer = chat.app.pmchat.pmSmsChat.getConvoVideoLink(conversationId, convo.site_token);
        //             if(chat.videoChatVersion == 1){
        //                 videoCallUrlDealer = chat.app.pmchat.pmSmsChat.getConvoVideoLinkV1(conversationId);                        
        //             }
        //         chat.startVideoCall(videoCallUrlDealer);
        //         }
        // });


        //BACK BTN
        DOMHelper.addHandler(chat.app.getSelector(".pmc-back-btn"), 
            "click", () => {
            switch(chat.currentWindow){
                case 'customer': chat.showChat(); break;
                default: chat.showHome();
            }            
        }); 

        //SHOW CUSTOMER INFO
        DOMHelper.addHandler(chat.app.getSelector(".pmc-user-icon"), 
            "click", () => {
            chat.showCustomerInfo();
        });
                
        DOMHelper.addHandler(chat.app.getSelector(".conversation .pmc-details-btn"), 
            "click", () => {
               chat.showCustomerInfo();
        });

        //EDIT LIST MODE
        DOMHelper.addHandler(chat.app.getSelector(".pmc-main-chat .pmc-menu-btn"), 
            "click", () => {
            chat.editListMode(true);
        });

        DOMHelper.addHandler(chat.app.getSelector(".pmc-main-chat .pmc-cancel-btn"), 
            "click", () => {
            chat.editListMode(false);            
        });

        //EDIT CHAT MODE
        DOMHelper.addHandler(chat.app.getSelector(".conversation .pmc-menu-btn"), 
            "click", () => {
            chat.editChatMode(true);            
        });

        DOMHelper.addHandler(chat.app.getSelector(".conversation .pmc-cancel-btn"), 
            "click", () => {
            chat.editChatMode(false);            
        });

        //DELETE SELECTED CONVOS
        DOMHelper.addHandler(chat.app.getSelector(".pmc-main-chat .pmc-delete-btn"), 
            "click", () => {
            if( chat.selectedConvoList.length > 0
                && window.confirm("Are you sure?")){
                chat.selectedConvoList.forEach( conversationObject =>{
                    chat.deleteConversation(conversationObject);
                }) ;
                chat.editListMode(false);
            }
        });       

        //DELETE CURRENT CONVO
        DOMHelper.addHandler(chat.app.getSelector(".conversation .pmc-delete-btn"), 
            "click", () => {
            if(window.confirm("Are you sure?")){
                chat.deleteConversation(chat.selectedConversation,
                    () => {                    
                        chat.editChatMode(false);
                        chat.showHome();
                    });
            }
        });

        //UPLOAD BTN
        DOMHelper.addHandler(chat.app.getSelector(".upload-img-btn"), 
            "click", (e) => {
            var target = e.target.dataset.target;
            var selector = chat.app.getSelector(target);            
            console.log(selector);
            chat.uploadImageModal.show();
            document.querySelector(selector).click();
        });

        console.log('jd selector');
        console.log(chat.app.getSelector(".pmc-image-input"));
        //IMAGE PREVIEW
        DOMHelper.addHandler(chat.app.getSelector(".pmc-image-input"), 
            "change", (e) => {                
            var selector = chat.app.getSelector("#" + e.target.id + "-preview");                        
            var preview = document.querySelector(selector);
            const [file] = e.target.files
            if (file) {
                preview.src = URL.createObjectURL(file)
            }
        });

        //SUBMIT UPLOAD IMAGE
        DOMHelper.addHandler(
            chat.app.getSelector('.upload-image-form'),
            'submit',
            (e) => {
                e.preventDefault();
                //FORM DATA AS JSON
                const data = new FormData(e.target);
                const fileInput = e.target.querySelector('.pmc-image-input');
                data.append("img", fileInput.files[0]);

                //DISABLE SUBMIT BTN
                var submitBtn = e.target.querySelector(".pmc-button");
                submitBtn.disabled = true;

                console.log(data);
                var promise = chat.app.pmchat.pmSmsChat.postDealerImageMessage(
                    data,
                    //success
                    (response) => {
                        console.log(response);
                        response = JSON.parse(response);
                        chat.addMessageHistory({
                            author: chat.site_token,
                            body: "",
                            media: response.media

                        }, true);
                        chat.fetchConversationImages();
                        chat.uploadImageModal.hide();
                        submitBtn.disabled = false;

                    },
                    //error
                    () => {
                        console.error('Error sending dealer image message')
                        submitBtn.disabled = false;
                    }
                );


            });

        DOMHelper.addHandler(
            chat.app.getSelector(".start-call-btn"),
            'click',
            (e) => {                
                //OUTGOING CALL
                chat.startVoiceCall(chat.selectedConversation);
            }
        );

        //HANG UP CALL
        DOMHelper.addHandler(
            chat.app.getSelector(".end-voice-call-btn"),
            'click',
            (e) => {                
                chat.voiceCallModal.hide();//modal hidding calls voiceCall.end
            }
        );

        //MUTE UNMUTE
        DOMHelper.addHandler(
            chat.app.getSelector(".mute-voice-call-btn"),
            'click',
            (e) => {                
                var isMuted = chat.currentVoiceCall.toggleMuteVoiceCall();
                DOMHelper.toggleHide(chat.app.getSelector('.mute-voice-call-btn'))
            }
        );        

        // FLOATING MENU CURTAIN CLICK
         DOMHelper.addHandler(
            chat.app.getSelector(".pmc-floating-menu-curtain"),
            'click',
            (e) => {                
                chat.editChatMode(false);            
            }
        );        
           
    }    

    addIframeMessagesHandlers() {
        var chat = this;
        window.onmessage = function(e) {
            if (e.data == 'video_call_end') {
                chat.closeVideoCall()
            }
        };        
    }

    getVideoCallElement(){
        return document.querySelector(this.app.getSelector('.pm-chat-video-call'));
    }

    startVideoCall(videoCallUrl){
        var videoCallElement = this.getVideoCallElement();
        var iframe = videoCallElement.querySelector('iframe');
        iframe.src = videoCallUrl;
        DOMHelper.addClass(videoCallElement, 'active');
    }

    closeVideoCall(){
        var videoCallElement = this.getVideoCallElement();
        var iframe = videoCallElement.querySelector('iframe');
        iframe.src = "";
        DOMHelper.removeClass(videoCallElement, 'active');
    }

    fetchConversationImages(){
        let chat = this;
        DOMHelper.querySelectorAction(
            chat.app.getSelector('.msg-media-img'),
            (img) => {
                if (img.getAttribute('src').length){
                    return;//SKIP
                }
                let mediaSid = img.dataset.mediasid
                chat.app.pmchat.pmSmsChat.getMediaSource(
                    chat.selectedConversation._id, 
                    mediaSid,
                    (response) => {
                        let mediaSource = JSON.parse(response);
                        img.src = mediaSource.mediaUrl
                    },
                    () => {
                        console.error('Error fetching images')
                    }
                )
            }
        );
    }

    openDefaultConversation(){        
        var searchConvoId = this.app.config.conversationId;
        if(searchConvoId){
            if(!this.defaultConversationOpened){
                this.defaultConversationOpened = true;//running only one time
                let conversationObject = this.conversations.find(o => o._id == searchConvoId);
                if(conversationObject){
                    this.selectConversation(conversationObject);
                }
            }
        }
    }

    clearConversationsList(){
        let selector = this.app.getSelector('.conversations-list');
        let list = document.querySelector(selector);    
        list.innerHTML = "";
    }

    loadConversations(){
        var chat = this;
        chat.app.pmchat.pmSmsChat.getConversations(
            (response)=>{
                chat.clearConversationsList();
                chat.conversations = JSON.parse(response);
                chat.conversations.forEach((conversationObject) => {                    
                    chat.appendConversation(conversationObject);
                });
                console.log(chat.conversations);
                chat.openDefaultConversation();

            }, 
            () => {
                console.error('Error getting conversations')
            }
        )
    }

    loadConversationMessages(conversationId){
        var chat = this;
        chat.showLoading("conversation");
        return this.app.pmchat.pmSmsChat.getConversationMessages(
            conversationId,
            (response) => {
                let messages = JSON.parse(response);
                messages.reverse().forEach((messageObject) => {                    
                    chat.addMessageHistory(messageObject, true);
                });
                chat.fetchConversationImages();
                console.log(messages);
                chat.hideLoading("conversation");
            }, 
            () => {
                console.log('Error getting messages')
            }
        )
    }

    loadVehicleInfo(vin){
        var chat = this;        
        this.app.pmchat.pmApiDtm.getVehicle(
            vin,
            (response) => {
                let vehicle = JSON.parse(response);       
                vehicle = vehicle.vehicle;         
                console.log(vehicle);     
                //TEMPLATE           
                let vehicleContent = `<li>
    <span class="pmc-voi-left">vehicle_label<br/>
        <span class="pmc-voi-vin">
            vehicle_vin
        </span>
    </span>
    <span class="pmc-voi-right">
        <a href="#">
            <i class="pmc-fa pmc-fa-magnifying-glass"></i>
        </a>
    </span>
</li>`;
                vehicleContent = vehicleContent
                    .replace(/vehicle_label/g, vehicle.label)
                    .replace(/vehicle_vin/g, vin);

                //TEMP CONTAINER
                let div = document.createElement('div');
                div.innerHTML = vehicleContent
                var vehiclesList = document.querySelector(chat.app.getSelector('.pmc-voi-info'));
                vehiclesList.innerHTML = "";
                vehiclesList.append(div.firstChild);

            }, 
            () => {
                console.log('Error getting vehicle info')
            }
        )
    }

    showLoading(type) {
        document.querySelector(this.app.getSelector("." + type + "-loading")).style.display = "block";
    }

    hideLoading(type) {
        document.querySelector(this.app.getSelector("." + type + "-loading")).style.display = "none";
    }
}
