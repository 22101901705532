import { RequestHelper } from "../Helpers/RequestHelper.js";

export class PmSmsChat {
    constructor(pmchat) {
        this.pmchat = pmchat;
    }

    getRootURL() {
        if (this.pmchat.isProduction()) {
            return "https://smschat.dev.pixelmotiondemo.com/";
        }
        // return "http://localhost/";//video and audio only works on localhost
        return "http://pm-sms-chat.loc/";        
    }

    buildURL(url) {
        return this.getRootURL() + "api/" + url;
    }

    getTinyUrl(url, successCallback, errorCallback){
        RequestHelper.ajax(
            "post",
            this.buildURL('tinyUrl/create'),
            {url:url},
            successCallback,
            errorCallback
        );
    }

    getConvoVideoLink(conversationId, identity){
        return this.getRootURL() 
            + `conversation/${conversationId}/video/${identity}`;
    }

    getConvoVideoLinkV1(conversationId){
        return this.getRootURL() 
            + `conversation/${conversationId}/v1/video`;
    }
    
    getConversations(successCallback, errorCallback) {        
        RequestHelper.ajax(
            "GET",
            this.buildURL('conversation/list/' + this.pmchat.config.site_token),
            {},
            successCallback,
            errorCallback
        );
    }

    getConversationMessages(conversationId, successCallback, errorCallback) {        
        RequestHelper.ajax(
            "GET",
            this.buildURL('conversation/' + conversationId + "/messages"),
            {},
            successCallback,
            errorCallback
        );
    }

    postDealerMessage(data, successCallback, errorCallback) {        
        RequestHelper.ajax(
            "post",
            this.buildURL('conversation/dealerMessage'),
            data,
            successCallback,
            errorCallback
        );
    }

    postDealerImageMessage(data, successCallback, errorCallback) {        
        RequestHelper.postMultipart(            
            this.buildURL('conversation/dealerImageMessage'),
            data,
            successCallback,
            errorCallback
        );
    }

    deleteConversation(data, successCallback, errorCallback) {        
        RequestHelper.ajax(
            "post",
            this.buildURL('conversation/delete'),
            data,
            successCallback,
            errorCallback
        );
    }

    getMediaSource(conversationId, mediaSid, successCallback, errorCallback) {        
        RequestHelper.ajax(
            "GET",
            this.buildURL('twilio/conversation/' + conversationId + "/fetchMediaSource/" + mediaSid),
            {},
            successCallback,
            errorCallback
        );
    }

    getVoiceAccessToken(conversationId, successCallback, errorCallback) {        
        return RequestHelper.ajax(
            "GET",
            this.buildURL('conversation/' + conversationId + "/voice/accessToken"),
            {},
            successCallback,
            errorCallback
        );
    }

    getDealerSettings(site_token, successCallback, errorCallback) {        
        return RequestHelper.ajax(
            "GET",
            this.buildURL('dealerSettings/'),
            {site_token: site_token},
            successCallback,
            errorCallback
        );
    }
}
