import { Device } from '@twilio/voice-sdk';

export class VoiceCall {
    /**
     * @param  {string} parentId dom element id
     * @return {void}
     */
    constructor(app, conversationObject) {
        this.app = app;        
        this.conversationObject = conversationObject;
        this.localMediaStream = null;        
        this.twDevice = null;
        this.twCall = null;

    }

    start(){
        var voiceCall = this;
        navigator.mediaDevices.getUserMedia ({audio: true})
            .then(function (localMediaStream) {
                voiceCall.localMediaStream = localMediaStream;                                    
                voiceCall.createTwilioDevice((twDevice) =>{
                    voiceCall.twDevice = twDevice;
                    voiceCall.connectToTwilioApp()
                });

            }).catch(function(err) {
              // Explain why you need permission and how to update the permission setting
                console.error(`you got an error: ${err}`);  
            });  
    }

    end() {
        var voiceCall = this;
        this.twDevice.destroy();
        this.localMediaStream.getTracks().forEach(track=>{
            track.stop();
        });
    }


    toggleMuteVoiceCall(){
        var voiceCall = this;                
        var isMuted = voiceCall.twCall.isMuted();
        voiceCall.twCall.mute(!isMuted);
        

        // voiceCall.localMediaStream.getTracks().forEach(track=>{            
        //     track.enabled = shouldMute;
        // });

        return !isMuted; //is muted
    }

    createTwilioDevice(callback) {
        var voiceCall = this;
        //CREATE TW DEVICE
        return this.app.pmchat.pmSmsChat.getVoiceAccessToken(this.conversationObject._id,
            (response) => {
                response = JSON.parse(response);                
                console.log(response);
                var device = new Device(response.access_token,{
                    logLevel: 1, //0 = "trace", 1 = "debug", 2 = "info", 3 = "warn", 4 = "error", 5 = "silent"
                });               

                device.on('registered', device => {
                  console.log('The device is ready to receive incoming calls.')
                });

                callback(device);
            },
            (error) => {
                console.error('Error getting voice accessToken');
            }
        );
    }

    async connectToTwilioApp() {    
        var voiceCall = this;     
        console.log("Connecting...");       
        var outgoingConnection = await voiceCall.twDevice.connect({
            params: {
                // pm_conversation_id: voiceCall.conversationObject._id                
                From: voiceCall.app.pmchat.dealerSettings.api_from_number,
                To: voiceCall.conversationObject.customer_phone
                // To: '+19032962250'//TWILIO DEMO PURPOSES NUMBER
                
            }
        });                


        console.log(outgoingConnection);
        voiceCall.twCall = outgoingConnection;
    }      
}      