
export class SocketioHandler {
    /**
     * @param  {string} parentId dom element id
     * @return {void}
     */
    constructor(app) {
        this.app = app;
        if((!'io' in window)){
            console.log("Socket io missing on website");
            return;
        }
        
        let socketServer = this.app.pmchat.isProduction()
            ? "https://smschat.dev.pixelmotiondemo.com:3000"
            : "http://localhost:3000";

        this.socket = io(socketServer);
        console.info("Socket io connnected to " + socketServer);
    }

    init() {        
        var $this = this;
        this.socket.on("messageAdded", (message) => {
            console.log('Message from socketio');            
            console.log(message);            
            $this.app.chat.handleIncomingMsg({
                author: message.Author,
                body: message.Body,
                conversationSid: message.ConversationSid,                
                dateCreated: null,                
                media: message.Media,                
            })
        });

        this.socket.on("conversationAdded", (conversation) => {
            console.log(conversation);
            if(conversation.site_token == $this.app.pmchat.config.site_token){
                $this.app.chat.appendConversation(conversation, true)
            }
        })
    }      
}      