export class DOMHelper {
    constructor() {}

    //EVENTS
    static addHandler(selectorOrElement, eventName, callback) {
        if (selectorOrElement.nodeName != undefined) {
            // if it's element
            selectorOrElement.addEventListener(eventName, callback);
        } else {
            let matches = document.querySelectorAll(selectorOrElement);

            for (let i = 0; i < matches.length; i++) {
                matches[i].addEventListener(eventName, callback);
            }
        }
    }

    /**
     * @param  {[string]} selector  [description]
     * @param  {[string]} eventName [description]
     */
    static triggerEvent(selectorOrElement, eventName) {
        if (selectorOrElement.nodeName != undefined) {
            // if it's element
            selectorOrElement.dispatchEvent(new Event(eventName));
        } else {
            DOMHelper.querySelectorAction(selectorOrElement, (item) => {
                item.dispatchEvent(new Event(eventName));
            });
        }
    }

    //CLASSES
    static hasClass(element, className) {
        var className = " " + className + " ";
        return (
            (" " + element.className + " ")
                .replace(/[\n\t]/g, " ")
                .indexOf(className) > -1
        );
    }

    static addClass(selectorOrElement, className) {
        if (selectorOrElement.nodeName != undefined) {
            // if it's element
            selectorOrElement.classList.add(className);
        } else {
            let matches = document.querySelectorAll(selectorOrElement);

            for (let i = 0; i < matches.length; i++) {
                matches[i].classList.add(className);
            }
        }
    }

    static removeClass(selectorOrElement, className) {
        if (selectorOrElement.nodeName != undefined) {
            // if it's element
            selectorOrElement.classList.remove(className);
        } else {
            let matches = document.querySelectorAll(selectorOrElement);

            for (let i = 0; i < matches.length; i++) {
                matches[i].classList.remove(className);
            }
        }
    }

    static toggleClass(selectorOrElement, className) {
        if (selectorOrElement.nodeName != undefined) {
            // If it's an element
            selectorOrElement.classList.toggle(className);
        } else {
            // If it's a selector
            let matches = document.querySelectorAll(selectorOrElement);
    
            for (let i = 0; i < matches.length; i++) {
                matches[i].classList.toggle(className);
            }
        }
    }

    //GENERAL
    static setInnerHTML(selector, html) {
        DOMHelper.querySelectorAction(selector, (item) => {
            item.innerHTML = html;
        });
    }

    static setValue(selector, value) {
        DOMHelper.querySelectorAction(selector, (item) => {
            item.value = value;
        });
    }

    static setAttribute(selector, attribute, value) {
        DOMHelper.querySelectorAction(selector, (item) => {
            item[attribute] = value;
        });
    }

    static toggleHide(selector){                
        DOMHelper.querySelectorAction(selector, (item) => {
            if (item.style.display === "none") {
                item.style.display = "block";
            } else {
               item.style.display = "none";
            }
        });
    }

    //INPUT
    // Restricts input for the given textbox to the given inputFilter function.
    static setInputFilter(textbox, inputFilter, preFilter) {
        [
            "input",
            "keydown",
            "keyup",
            "mousedown",
            "mouseup",
            "select",
            "contextmenu",
            "drop",
        ].forEach(function (event) {
            textbox.addEventListener(event, function (event2) {
                if (preFilter) {
                    this.value = preFilter(this.value);
                }
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(
                        this.oldSelectionStart,
                        this.oldSelectionEnd
                    );
                } else {
                    this.value = "";
                }
            });
        });
    }

    //UTIL
    static querySelectorAction(selector, callback) {
        let matches = document.querySelectorAll(selector);

        for (let i = 0; i < matches.length; i++) {
            callback(matches[i]);
        }
    }

    static exec_body_scripts(body_el) {
        // Finds and executes scripts in a newly added element's body.
        // Needed since innerHTML does not run scripts.
        //
        // Argument body_el is an element in the dom.

        function nodeName(elem, name) {
            return (
                elem.nodeName &&
                elem.nodeName.toUpperCase() === name.toUpperCase()
            );
        }

        function evalScript(elem) {
            var data = elem.text || elem.textContent || elem.innerHTML || "",
                head =
                    document.getElementsByTagName("head")[0] ||
                    document.documentElement,
                script = document.createElement("script");

            script.type = "text/javascript";
            try {
                // doesn't work on ie...
                script.appendChild(document.createTextNode(data));
            } catch (e) {
                // IE has funky script nodes
                script.text = data;
            }

            head.insertBefore(script, head.firstChild);
            head.removeChild(script);
        }

        // main section of function
        var scripts = [],
            script,
            children_nodes = body_el.childNodes,
            child,
            i;

        for (i = 0; children_nodes[i]; i++) {
            child = children_nodes[i];
            if (
                nodeName(child, "script") &&
                (!child.type || child.type.toLowerCase() === "text/javascript")
            ) {
                scripts.push(child);
            }
        }

        for (i = 0; scripts[i]; i++) {
            script = scripts[i];
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
            evalScript(scripts[i]);
        }
    }
}
