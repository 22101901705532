import { RequestHelper } from "../Helpers/RequestHelper.js";

export class PmShortUrl {
    constructor(pmchat) {
        this.pmchat = pmchat;
    }

    getRootURL() {
        if (this.pmchat.isProduction()) {
            return "https://pmdrs.co/";
        }
        return "https://pmdrs.co/";
    }

    buildURL(url) {
        return this.getRootURL() + "api/" + url;
    }

    getShortUrl(url, successCallback, errorCallback){
        RequestHelper.ajax(
            "post",
            this.buildURL('createShortUrl'),
            {url:url},
            successCallback,
            errorCallback
        );
    }
}
