export class FormatHelper {

    static getHumanDate(date){
        return (date.getMonth()+1) + "/"
         +  date.getDate() + "/"
         +  date.getFullYear();
    }

    static getDateToCompare(date){
        return date.getFullYear().toString() 
        + (date.getMonth()+1).toString()
        +  date.getDate().toString() ;
    }

    static dateToTime(date){
        return date.getHours().toString().padStart(2,'0') 
        +":"
        +date.getMinutes().toString().padStart(2,'0');
    }

    static dateToAmPm(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    static currencyToFloat(stringVal) {
        return Number(stringVal.replace(/[^0-9.-]+/g, ""));
    }

    static currencyFormat(number, decimals) {
        decimals = decimals == null ? 2 : decimals;
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            // These options are needed to round to whole numbers if that's what you want.
            minimumFractionDigits: decimals, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: decimals, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(number);
    }

    static serialize(obj) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(
                    encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                );
            }
        return str.join("&");
    }

    static formToArray(form) {
        var field,
            l,
            s = [];
        if (typeof form == "object" && form.nodeName == "FORM") {
            var len = form.elements.length;
            for (var i = 0; i < len; i++) {
                field = form.elements[i];
                if (
                    field.name &&
                    !field.disabled &&
                    field.type != "file" &&
                    field.type != "reset" &&
                    field.type != "submit" &&
                    field.type != "button"
                ) {
                    if (field.type == "select-multiple") {
                        l = form.elements[i].options.length;
                        for (j = 0; j < l; j++) {
                            if (field.options[j].selected)
                                s[s.length] = {
                                    name: field.name,
                                    value: field.options[j].value,
                                };
                        }
                    } else if (
                        (field.type != "checkbox" && field.type != "radio") ||
                        field.checked
                    ) {
                        s[s.length] = { name: field.name, value: field.value };
                    }
                }
            }
        }
        return s;
    }

    static arrayOfObjectsToKeyValue(array, key, valueKey) {
        var output = {};
        for (var i = 0; i < array.length; i++) {
            output[array[i][key]] = array[i][valueKey];
        }

        return output;
    }

    static ucfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * only working for numbers. Creater for ssn and phone numbers format
     * @param String string [description]
     * @param int num1   [description]
     * @param int num2   [description]
     */
    static addDashes(string, num1, num2) {
        var val = string;
        var regex = /(\d)/g;
        var matches = val.match(regex);
        if (val.length > num1 && val.length <= num2) {
            matches.splice(num1, 0, "-");
        }
        if (val.length > num2) {
            matches.splice(num1, 0, "-");
            matches.splice(num2 + 1, 0, "-");
        }
        var val = matches.join("");
        return val;
    }

    static ssnFormat(value) {
        return FormatHelper.addDashes(value, 2, 4).substr(0, 10);
    }

    static phoneNumberFormat(value) {
        return FormatHelper.addDashes(value, 3, 6).substr(0, 12);
    }

    static noNumbers(string) {
        return string.replace(/[0-9]/g, "");
    }

    static vinFormat(value) {
        return value.substr(0, 17);
    }
}
