import { DOMHelper } from "../Helpers/DOMHelper.js";

export class Modal {
    constructor(app, classAtt, content, openBtnSelector, additionalClasses) {
        this.app = app;
        this.parentId = app.config.elementId;
        this.classAtt = classAtt;
        this.content = content;
        this.openBtnSelector = openBtnSelector;
        this.additionalClasses = additionalClasses || "";
        this.hideCallback = null;
    }

    addHtml() {
        // <span class="chat-close-modal-btn">x</span>
        let html =
            `<div class="pm-chat-modal ` +
            this.classAtt +
            " " +
            this.additionalClasses +
            `">
			<div class="chat-modal-background"></div>
			<div class="chat-modal-content">
				<button type='button' class='chat-close-modal-btn'>
				</button>
				<div class="chat-modal-body">` +
            this.content +
            `</div>
			</div>
		</div>
		`;

        document.querySelector(".pm-chat-modals").insertAdjacentHTML("beforeend", html);
    }

    init() {
        this.addHtml();
        this.addHandlers();
    }

    addHandlers() {
        let thisModal = this;

        DOMHelper.addHandler(this.getSelector(".chat-modal-background"), "click", () => {
            thisModal.hide();
        });

        DOMHelper.addHandler(this.getSelector(".chat-close-modal-btn"), "click", () => {
            document.querySelector("body").style.setProperty("position", "inherit", "important");
            thisModal.hide();
        });

        DOMHelper.addHandler(this.app.getSelector(this.openBtnSelector), "click", () => {
            thisModal.show();
        });
    }

    getModalElement() {
        return document.querySelector(this.app.getSelector("." + this.classAtt + ".pm-chat-modal"));
    }

    show() {
        document.querySelector("body").style.overflowY = "hidden";
        this.getModalElement().classList.add("show");
    }

    hide() {
        document.querySelector("body").style.overflowY = "auto";
        this.getModalElement().classList.remove("show");
        if (this.hideCallback) {
            this.hideCallback();
        }
    }

    setHideCallback(callback) {
        this.hideCallback = callback;
    }

    getSelector(selector) {
        return ".pm-chat-modals ." + this.classAtt + " " + selector;
    }
}
