import { RequestHelper } from '../Helpers/RequestHelper.js';
import { Chat } from './Chat.js';
import { SocketioHandler } from './SocketioHandler.js';

export class AdminApp {
	
	constructor(pmchat, config){		
		let app = this;
		app.pmchat = pmchat;
		//CONFIG		
		this.config = this.defaultConfig();		
		Object.assign(this.config, config);
		
		//ATTS
		this.wrapperClass = 'pm-chat-admin-app';
        this.wrapperClassId = "pm-chat-" + this.config.elementId;

		//init
		this.init();



		this.chat = new Chat(app);
		this.chat.init();


		this.socketioHandler = new SocketioHandler(app);
		this.socketioHandler.init();

	}


	defaultConfig(){
		return {
			elementId: "",				
			conversationId: null,//the conversation to open by default
		};
	}

	init(){
		this.addHtml();
	}	

	addHtml(){
		var classes = [
			this.wrapperClass,
            this.wrapperClassId
		].join(" ");

		document.querySelector("#" + this.config.elementId)
			.insertAdjacentHTML('beforeend',`<div class="` + classes +`"></div>`);		

		//SPOT FOR MODALS HTML
        document
            .querySelector("body")
            .insertAdjacentHTML(
                "beforeend",
                "<div class='" + classes + " pm-chat-modals'></div>"
            );
	}

	getSelector(selector){
        return "." + this.wrapperClass + "." + this.wrapperClassId + " " + selector;
		// return "#" + this.config.elementId + " ."+ this.wrapperClass +" " + selector;
	}
}
